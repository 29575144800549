var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("label", { staticClass: "app-timepicker", class: _vm.styles }, [_vm.label !== "" || _vm.$slots.label ? _c("span", { staticClass: "app-timepicker__label" }, [_vm._t("label", function() {
    return [_vm._v(_vm._s(_vm.label))];
  }), _vm.isAsteriskShown || _vm.showAsterisk ? _c("span", { staticClass: "app-timepicker__asterisk" }, [_vm._v(" * ")]) : _vm._e()], 2) : _vm._e(), _c("div", { staticClass: "app-timepicker__wrapper", attrs: { "id": "timepicker__wrapper" } }, [_c("VueTimepicker", { attrs: { "format": _vm.format === "12h" ? "hh:mm a" : "HH:mm", "hour-range": _vm.hourRange, "placeholder": _vm.placeholder, "disabled": _vm.disabled, "hide-clear-button": _vm.hideClearButton, "input-class": "skip-error-style", "hour-label": "Hrs.", "minute-label": "Min.", "apm-label": " ", "drop-direction": "auto", "auto-scroll": "", "manual-input": "", "close-on-complete": "", "advanced-keyboard": "" }, on: { "focus": function($event) {
    _vm.focused = true;
  }, "blur": function($event) {
    _vm.focused = false;
  } }, scopedSlots: _vm._u([{ key: "clearButton", fn: function() {
    return [_c("Icon", { attrs: { "icon": "close" } })];
  }, proxy: true }]), model: { value: _vm.internalModelComputed, callback: function($$v) {
    _vm.internalModelComputed = $$v;
  }, expression: "internalModelComputed" } })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
