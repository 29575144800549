const routes = [
  {
    path: "/dashboards",
    name: "superset-dashboards",
    component: () => import("@/views/superset/DashboardsList"),
    meta: {
      requireAuth: true,
      module: "reports",
      publicName: "Dashboards"
    }
  },
  {
    path: "/dashboards/:dashboardSlug",
    name: "superset-dashboards",
    component: () => import("@/views/superset/DashboardView"),
    meta: {
      requireAuth: true,
      module: "reports",
      publicName: "Dashboards",
      backTo: "superset-dashboards",
      backToName: "Regresar"
    }
  }
];
export default routes;
