var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", { staticClass: "relative hover:cursor-pointer border border-[var(--brand-primary)] flex p-2 rounded hover:bg-main-50 hover:border-main-900", class: {
    "items-center justify-center border-dashed": !_vm.internalFile,
    "items-center justify-center": _vm.preview,
    " min-h-[100px]": !_vm.inline
  } }, [!_vm.internalFile && !_vm.preview ? _c("div", { staticClass: "text-[var(--link-color)] text-center", class: {
    "flex items-center gap-3": _vm.inline
  } }, [_c("Icon", { staticClass: "text-2xl", attrs: { "icon": "upload" } }), _c("p", { staticClass: "text-center text-sm" }, [_vm._v(" " + _vm._s(_vm.labelUpload) + " ")])], 1) : _c("div", [_c("div", { staticClass: "font-medium text-[var(--link-color)] flex items-center" }, [!_vm.preview ? _c("div", [_vm._v(" " + _vm._s(_vm.internalFile.name) + " "), _vm.clearable ? _c("span", { staticClass: "ml-1", on: { "click": () => _vm.internalFile = null } }, [_c("Icon", { attrs: { "icon": "close-circle" } })], 1) : _vm._e()]) : _c("div", [_c("img", { staticClass: "h-[80px] w-auto", attrs: { "src": _vm.preview } })])]), _vm.helpText ? _c("div", { staticClass: "text-fs-14" }, [_vm._v(" " + _vm._s(_vm.helpText) + " ")]) : _vm._e()]), _c("input", { ref: "inputFile", staticClass: "absolute inset-0 w-full h-full opacity-0 z-20 hover:cursor-pointer", class: {
    "hidden": _vm.clearable && _vm.internalFile
  }, attrs: { "type": "file", "accept": _vm.accept }, on: { "change": _vm.onChange } }), _vm.temporalFile ? _c("ImageCropper", { attrs: { "title": _vm.cropperTitle, "stencil-props": {
    aspectRatio: _vm.aspectRatio,
    minAspectRatio: _vm.aspectRatio
  }, "min-width": _vm.minWidth, "image": _vm.temporalFile }, on: { "saveFile": _vm.onSaveFile, "close": () => _vm.cropPromise(null) } }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };
