var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { superset } from "@/api/superset";
const state = {
  supersetDashboard: [],
  currentActiveSupersetDashboard: null,
  loading: false
};
const actions = {
  getSupersetDashboards(_0) {
    return __async(this, arguments, function* ({ commit }) {
      try {
        const list = (yield superset.get_dashboards_by_company()).data;
        commit("SET_LIST", list);
        return list;
      } catch (_) {
        commit("SET_LIST", []);
      }
    });
  },
  setCurrentActiveSupersetDashboard({ commit }, supersetDashboard) {
    try {
      commit("SET_CURRENT_ACTIVE_SUPERSET_DASHBOARD", supersetDashboard);
    } catch (_) {
      commit("SET_CURRENT_ACTIVE_SUPERSET_DASHBOARD", null);
    }
  }
};
const mutations = {
  SET_LIST(state2, list) {
    state2.supersetDashboard = list;
  },
  SET_CURRENT_ACTIVE_SUPERSET_DASHBOARD(state2, supersetDashboard) {
    state2.currentActiveSupersetDashboard = supersetDashboard;
  }
};
export default {
  state,
  actions,
  mutations,
  namespaced: true
};
