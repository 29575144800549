var render = function render2() {
  var _vm = this, _c = _vm._self._c;
  return _c("div", [_c("div", { staticClass: "fixed top-0 bottom-0 right-0 left-0 bg-black w-full h-screen z-[998] opacity-32 cursor-pointer", on: { "click": _vm.drawerShow } }), _c("div", { staticClass: "drawer fixed right-0 top-0 bg-white bottom-0 h-screen flex flex-col" }, [_vm.customHeader ? _c("div", { class: {
    "border-b border-grey": !_vm.hasTabs,
    "p-6": !_vm.hasTabs,
    "pt-6 px-6": _vm.hasTabs
  } }, [_vm._t("header")], 2) : _c("div", { staticClass: "p-6 border-b border-grey flex flex-row justify-between flex-shrink-0 flex-grow-0" }, [_c("div", { staticClass: "flex flex-col" }, [_vm.backLabelActive ? _c("div", { staticClass: "flex flex-row content-center", on: { "click": _vm.drawerShow } }, [_c("LeftArrow", { staticClass: "mt-1 text-[var(--link-color)] h-icon" }), _c("button", { staticClass: "text-[var(--link-color)] ml-1 outline-none" }, [_vm._v(" " + _vm._s(_vm.backLabel) + " ")])], 1) : _vm._e(), _c("span", { staticClass: "flex items-center text-fs-20 font-medium flex-auto" }, [_vm._t("title", function() {
    return [_vm._v(" " + _vm._s(_vm.title) + " ")];
  }, { "title": _vm.title })], 2)]), _c("span", { staticClass: "no-focus", attrs: { "role": "button", "theme": "ghost" }, on: { "click": _vm.drawerShow } }, [_vm._t("icon", function() {
    return [_c("CloseSmall")];
  })], 2)]), _c("div", { staticClass: "relative flex-shrink flex-grow" }, [_c("div", { staticClass: "body relative h-full p-6 overflow-auto", class: {
    "max-h-70vh": !!_vm.config && !_vm.heightFull,
    "custom-drawer-contain": _vm.heightFull || !_vm.config
  } }, [_vm._t("drawer-contain"), _vm._t("default")], 2)]), _c("div", { staticClass: "flex flex-row p-8 shadow-lg flex-shrink-0 flex-grow-0 footer" }, [_vm._t("footer"), _vm.config.activeButtonClose ? _c("div", { staticClass: "mr-6" }, [_c("Button", { attrs: { "theme": "secondary", "label": _vm.mainButtonLabel, "size": _vm.config.sizeButtons }, on: { "click": _vm.drawerShow } })], 1) : _vm._e(), _vm.config.secondButton ? _c("div", [_c("Button", { attrs: { "theme": _vm.config.secondButton.theme, "label": _vm.config.secondButton.label, "disabled": _vm.config.secondButton.disabled, "size": _vm.config.sizeButtons }, on: { "click": _vm.clickSuccess } })], 1) : _vm._e()], 2)])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };
